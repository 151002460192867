/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Property } from './property';


export interface Translation { 
    userid?: string;
    type?: Translation.TypeEnum;
    $context?: string;
    singular?: string;
    plural?: string;
    $cond?: Translation.CondEnum;
    $hidden?: boolean;
    ts?: string;
    info?: string;
    properties?: Property;
}
export namespace Translation {
    export type TypeEnum = 'TEXT' | 'LINK' | 'LIST';
    export const TypeEnum = {
        Text: 'TEXT' as TypeEnum,
        Link: 'LINK' as TypeEnum,
        List: 'LIST' as TypeEnum
    };
    export type CondEnum = 'isRepresentative' | 'isAssistant' | 'o365active' | '__not_o365active' | 'salesforce' | 'null';
    export const CondEnum = {
        IsRepresentative: 'isRepresentative' as CondEnum,
        IsAssistant: 'isAssistant' as CondEnum,
        O365active: 'o365active' as CondEnum,
        NotO365active: '__not_o365active' as CondEnum,
        Salesforce: 'salesforce' as CondEnum,
        Null: 'null' as CondEnum
    };
}



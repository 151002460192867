import { Injectable } from '@angular/core'
import {
  ApplicationInsights,
  ICustomProperties,
  IEventTelemetry,
  IExceptionTelemetry,
  IMetricTelemetry,
  IPageViewTelemetry,
  ITelemetryItem,
  ITraceTelemetry
} from '@microsoft/applicationinsights-web'
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js'
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js'
import { Router } from '@angular/router'
import { EnvironmentService } from './environment.service'
import { ExcludedTelemetryItem } from '../types'

@Injectable({
  providedIn: 'root'
})
export class InsightsService {

  insightsInstrumentation = ''
  cloudRoleName = ''
  avatarUrl = ''
  ssoUserIdUrl = ''

  angularPlugin = new AngularPlugin()
  clickPluginInstance = new ClickAnalyticsPlugin()
  clickPluginConfig = {
    autoCapture: true,
    dataTags: {
      useDefaultContentNameOrId: true
    }
  }

  appInsights: ApplicationInsights = {
    trackEvent(event: IEventTelemetry, customProperties?: ICustomProperties) {
      // empty
    },
    trackTrace(trace: ITraceTelemetry, customProperties?: ICustomProperties) {
      // empty
    },
    trackPageView(pageView?: IPageViewTelemetry) {
      // empty
    },
    trackMetric(metric: IMetricTelemetry, customProperties?: ICustomProperties) {
      // empty
    },
    trackException(exception: IExceptionTelemetry, customProperties?: ICustomProperties) {
      // empty
    }
  } as ApplicationInsights

  constructor(private router: Router, private envService: EnvironmentService) {
    this.loadEnv().then(() => {
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: this.insightsInstrumentation,
          enableAutoRouteTracking: true,
          maxBatchInterval: 0,
          samplingPercentage: 100,
          loggingLevelConsole: 2,
          loggingLevelTelemetry: 1,
          extensions: [this.angularPlugin, this.clickPluginInstance],
          extensionConfig: {
            [this.angularPlugin.identifier]: {
              router: this.router
            },
            [this.clickPluginInstance.identifier]: this.clickPluginConfig
          }
        }
      })

      this.init()
    })
  }

  async loadEnv() {
    const config = await this.envService.getConfig()
    this.insightsInstrumentation = config.apis.insightsInstrumentation
    this.cloudRoleName = config.apis.insightsCloudRoleName
    this.avatarUrl = config.apis.avatar
    this.ssoUserIdUrl = config.apis.ssoUserId
  }

  init() {
    this.appInsights.loadAppInsights()

    this.appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
      let itemTags = envelope.tags
      let itemData = envelope.baseData

      const excludedItems: Array<ExcludedTelemetryItem> = [{
        url: this.ssoUserIdUrl,
        responseCode: 200
      }, {
        url: this.avatarUrl,
        responseCode: 404
      }, {
        url: 'https://dpm.demdex.net/'
      }, {
        url: 'https://www.google-analytics.com'
      }, {
        url: 'https://region1.google-analytics.com'
      }, {
        url: 'https://api.userlane.com/'
      }, {
        url: 'https://auth.userlane.com/'
      }, {
        url: 'https://cdn.userlane.com/'
      }]

      const excludedUrls = excludedItems.filter((item: ExcludedTelemetryItem) => {
        return !!(itemData?.['name'] && itemData?.['responseCode'] && itemData?.['name'].includes(item.url) && itemData?.['responseCode'] === item.responseCode)
      })

      if (excludedUrls.length) {
        return false
      }

      if (itemTags) {
        itemTags = itemTags || []
        itemTags['ai.cloud.role'] = this.cloudRoleName
      }
      return true
    })
  }

  logPageView(name?: string, url?: string) {
    this.appInsights.trackPageView({
      name: name,
      uri: url
    })
  }

  logEvent(name: string, properties?: {[key: string]: any}) {
    this.appInsights.trackEvent({name: name}, properties)
  }

  logMetric(name: string, average: number, properties?: {[key: string]: any}) {
    this.appInsights.trackMetric({name: name, average: average}, properties)
  }

  logException(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({exception: exception, severityLevel: severityLevel})
  }

  logTrace(message: string, properties?: {[key: string]: any}) {
    this.appInsights.trackTrace({message: message}, properties)
  }
}

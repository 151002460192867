import { Injectable, Renderer2, RendererFactory2 } from '@angular/core'
import {
  Textservice,
  TextServiceReaderService as TextServiceApi,
  Translation as TextserviceTranslations
} from '../../generated/openapi/textservice'
import { EnvironmentService } from './environment.service'
import { firstValueFrom } from 'rxjs'
import { InsightsService } from './insights.service'

@Injectable({
  providedIn: 'root'
})
export class TextService {
  private static _localeFallback: TLocales = 'de-DE'
  private _translations: any = {}
  private _locale: TLocales = 'de-DE'
  private _editMode = false
  private _publishNeeded = false
  private renderer: Renderer2

  constructor(private textServiceApi: TextServiceApi, rendererFactory: RendererFactory2,
              private envService: EnvironmentService, private insightsService: InsightsService) {
    this.renderer = rendererFactory.createRenderer(null, null)
  }

  get(id: number, cnt?: number, ...values: Array<string>): TextserviceTranslations | string {
    const type = cnt && cnt > 1 ? 'plural' : 'singular'
    let translation: TextserviceTranslations =
      this._translations[this._locale].translations[(id as any)] ||
      this._translations[TextService._localeFallback].translations[(id as any)] ||
      TextService.getEmptyTranslation(id)

    // Catch empty texts and make them translatable
    let text = <string>translation[type] || TextService.getEmptyTranslation(id).singular
    values.forEach((value, idx) => {
      text = text?.replace(`%${idx}`, value)
    })

    translation.toString = (): string => {
      if (window.sessionStorage.getItem('text-editmode') === 'true') {
        window.setTimeout(() => {
          const elem = document.querySelector(`[data-text-id="${id}"]`)
          if (!elem) {
            return
          }
        }, 2000)
        return `${text} (${id})`
      }

      return <string>text
    }
    translation = this.filterConditions(translation)

    if (translation.type === 'LIST') {
      translation = this.filterList(translation)
    }

    return translation
  }

  setTranslations(locale: TLocales = 'de-DE', translations: TextserviceTranslations) {
    this._translations[locale] = {}
    this._translations[locale].translations = translations
  }

  async getTranslations(locale: TLocales = 'de-DE', editMode = false): Promise<Array<Textservice>> {
    return new Promise(
      resolve =>
        this.fetchTranslations(locale, editMode).then(() => {
          resolve(this._translations[locale]?.translations)
        })
    )
  }

  private async fetchTranslations(locale: TLocales, editMode = false): Promise<Textservice> {
    const currNew = (editMode && 'unpublished') || (!editMode && 'published') || 'published'
    this._editMode = editMode

    try {
      const config = await this.envService.getConfig()
      this.textServiceApi.configuration.basePath = config.apis.textservice

      const translations = await firstValueFrom(this.textServiceApi.read('meinedvag'))
      this._translations[locale] = await translations[currNew]
      this._publishNeeded = (JSON.stringify(translations['unpublished']) !== JSON.stringify(translations['published']))
      this._locale = locale
    } catch (e) {
      this._translations = <TextserviceTranslations>{
        lang: 'de_DE',
        translations: []
      }
      this.insightsService.logException(e as Error)
    }
    return this._translations
  }

  private filterList(translation: TextserviceTranslations) {
    const _translation = JSON.parse(JSON.stringify(translation))
    translation.properties?.listItems?.forEach((listItem, idx) => {
      const _listItem = this.get(listItem.$ref as number) as TextserviceTranslations
      _translation.properties.listItems[idx] = {
        '$ref': listItem.$ref,
        'label': _listItem.toString(),
        '$hidden': _listItem.$hidden
      }
    })
    return _translation
  }

  private filterConditions(translation: TextserviceTranslations): TextserviceTranslations {
    return translation
    /*
    type TPermissions = {
    'isRepresentative': boolean
    'isAssistant': boolean
    'o365active': boolean
    '!o365active': boolean
  }

    if (!translation.$cond) {
      return translation
    }

    const currentUser = User.Instance
    const cond = translation.$cond
    const perm: TPermissions = {
      'isRepresentative': (this._userType === 'vertreter'),
      'isAssistant': (this._userType === 'assistenten'),
      'o365active': (currentUser.data.o365active),
      '!o365active': (!currentUser.data.o365active)
    }
    translation.$hidden = !perm[cond]

    return translation*/
  }

  private static getEmptyTranslation(id: number): TextserviceTranslations {
    return {
      plural: 'Text ID: ' + id,
      singular: 'Text ID: ' + id,
      $hidden: false,
      type: 'TEXT'
    }
  }

  get publishNeeded(): boolean {
    return this._publishNeeded
  }

  getTextsForIds(source: Array<string> = [], ...ids: Array<number>): Array<string> {
    ids.forEach(id => {
      source[id] = this.get(id).toString()
    })

    return source
  }

  getTextsForIdsPlain(source: Array<string> = [], ...ids: Array<number>): Array<string> {
    ids.forEach(id => {
      source[id] = (this.get(id) as TextserviceTranslations).singular?.toString() || ''
    })

    return source
  }

  getTextsObjectsForIds(...ids: Array<number>): Array<TextserviceTranslations> {
    const textObjects: Array<TextserviceTranslations> = []
    ids.forEach(id => {
      textObjects[id] = this.get(id) as TextserviceTranslations
    })

    return textObjects
  }
}

type TLocales = 'de-DE' | 'en-EN' | 'fr-FR'
